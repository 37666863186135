import {handicaddieAsyncThunk} from "../../asyncThunk";
import {CaddieMaster, Club, Course, SlotType} from "../../../ApiDomain";
import {useApi} from "../../../useApi";

export const loadClubs = handicaddieAsyncThunk(
    "Clubs/loadClubs",
    async (_, thunkAPI) => {
        let state = thunkAPI.getState();
        let userId = state.currentUser.user?.id
        if ((state.clubs.allClubs && state.clubs.allClubs.length > 0) || !userId) {
            thunkAPI.abort()
            return {}
        }

        let caddieMasters: CaddieMaster[] = await useApi().getCaddieMastersForUser(userId)
        let allClubs: Club[] = await useApi().getListOfClubs()

        let caddieMasterClubs: Club[] = allClubs.filter((club: Club) =>
            caddieMasters.map((cm: CaddieMaster) => cm.clubId)
                .includes(club.id))

        let selectedClub: Club|undefined = undefined

        if (caddieMasterClubs && caddieMasterClubs.length >= 1) {
            selectedClub = caddieMasterClubs[0]
        }

        return {
            clubs: caddieMasterClubs,
            selectedClub: selectedClub,
        }
    }
);

export const loadSelectedClub = handicaddieAsyncThunk(
    "Clubs/loadSelectedClub",
    async (_, thunkAPI) => {
        let state = thunkAPI.getState();
        let userId = state.currentUser.user?.id
        if (!state.clubs.selectedClub.club || !userId) {
            thunkAPI.abort()
        } else {
            let clubId = state.clubs.selectedClub.club.id

            let courses: Course[] = await useApi().getClubCourses(clubId)
            let slotTypes: SlotType[] = await useApi().getSlotTypes(clubId)

            let names: Map<string, number> = new Map<string, number>();
            courses.forEach((c: Course) => {
                let nameCount = names.get(c.name)
                if (!nameCount) {
                    nameCount = 0
                }

                nameCount = nameCount + 1
                names.set(c.name, nameCount)
            });

            return {
                courses: courses,
                slotTypes: slotTypes,
                hasMultipleCourses: names.size > 1
            }
        }
    }
);

export const loadSelectedClubSlotTypes = handicaddieAsyncThunk(
    "Clubs/loadSelectedClubSlotTypes",
    async (_, thunkAPI) => {
        let state = thunkAPI.getState();
        let userId = state.currentUser.user?.id
        if (!state.clubs.selectedClub.club || !userId) {
            thunkAPI.abort()
        } else {
            let slotTypes: SlotType[] = await useApi().getSlotTypes(state.clubs.selectedClub.club.id)
            return slotTypes
        }
    }
);

export const loadSelectedClubCourses = handicaddieAsyncThunk(
    "Clubs/loadSelectedClubCourses",
    async (_, thunkAPI) => {
        let state = thunkAPI.getState();
        let userId = state.currentUser.user?.id
        if (!state.clubs.selectedClub.club || !userId) {
            thunkAPI.abort()
        } else {
            return await useApi().getClubCourses(state.clubs.selectedClub.club.id)
        }
    }
);
