import React, {useEffect, useState} from 'react';
import {Modal} from "react-bootstrap";
import ClubBookingForm from "./ClubBookingForm";
import {toast} from "react-toastify";
import {ClubBooking} from "../../../../services/ApiDomain";
import {useApi} from "../../../../services/useApi";
import {calculateSlotsWithNewCount} from "../../../../services/store/reducers/scheduler/service";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../services/store/store";
import {addBooking} from "../../../../services/store/reducers/scheduler/asyncActions";

type ClubBookingModalProps = {
    clubId: string
    modalTitle: string
    show: boolean
    showForm?: boolean
    existingBooking?: ClubBooking
    minCaddies?: number
    refreshBookings: () => void
    handleClose: () => void
}

const ClubBookingModal = ({modalTitle, show, showForm, existingBooking, minCaddies = 1, refreshBookings, handleClose}: ClubBookingModalProps) => {

    const { getBookingSlots } = useApi();

    const [title, setTitle] = useState<string>();
    const [numberOfCaddies, setNumberOfCaddies] = useState<number>();
    const [notes, setNotes] = useState<string>();

    const [actionsEnabled, setActionsEnabled] = useState<boolean>(true);

    const dispatch = useDispatch<AppDispatch>();

    useEffect(() => {
        if (!existingBooking) {
            toast.error('Unable to determine the booking you are editing.')
            return;
        }

        setTitle(existingBooking.title)
        setNotes(existingBooking.notes)
        setActionsEnabled(true);
    }, [existingBooking, show])

    const updateBooking = async () => {
        if (!numberOfCaddies) {
            toast.error('Unable to determine the number of caddies selected.')
            return;
        }

        if (!existingBooking) {
            toast.error("Can't update, unable to determine the existing booking.")
            return;
        }

        let id = existingBooking.bookingId

        let existingSlots = await getBookingSlots(id);
        let resp = calculateSlotsWithNewCount(existingSlots, numberOfCaddies)
        if (resp.error) {
            toast.warn('Booking was updated, but number of caddies was not.')
            return;
        }
        let slots = resp.slots.map(slot => {
            slot.bookingId = id
            return slot
        });

        dispatch(addBooking({
            bookingId: id,
            bookingSlots: slots,
            notes: notes,
            title: title,
            teeTime: existingBooking.teeTime,
        }))
        handleClose()
        refreshBookings()
    }

    return (
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{modalTitle}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {(showForm === undefined || showForm) &&
                    <ClubBookingForm
                        title={title}
                        updateTitle={setTitle}
                        numberOfCaddies={numberOfCaddies}
                        updateNumberOfCaddies={setNumberOfCaddies}
                        minCaddies={minCaddies}
                        notes={notes}
                        updateNotes={setNotes}
                        submit={updateBooking}
                        actionsEnabled={actionsEnabled}
                        closeBookingModal={handleClose}  />
                }
                {showForm === false && <p>Loading booking...</p>}
            </Modal.Body>
        </Modal>
    )
}

export default ClubBookingModal;