import React, {useEffect, useState} from 'react';
import {UserAvailability} from "../../../../../services/ApiDomain";
import moment from "moment";
import CaddieAvailabilityCard from "../../../../../components/club/caddie_card/CaddieAvailabilityCard";
import {Image, Spinner} from "react-bootstrap";
import {handicaddieSelector} from "../../../../../services/store/asyncThunk";

interface Props {
}

const AvailableCaddiesDrawerContent = ({}: Props) => {

    const [smartphoneAvailableCaddies, setSmartphoneAvailableCaddies] = useState<UserAvailability[]>()

    const { selectedDate, availableCaddies } = handicaddieSelector(state => state.scheduler)
    const { club } = handicaddieSelector(state => state.clubs.selectedClub)

    useEffect(() => {
        if (!availableCaddies) {
            return;
        }

        setSmartphoneAvailableCaddies(availableCaddies.filter(c => c.noSmartphoneCaddie === false))
    }, [availableCaddies])

    const dragStart = (e: React.DragEvent, caddieId: string) => {
        e.dataTransfer.setData('caddieId', caddieId);
    }

    const dragOver = (e: React.DragEvent) => {
        e.stopPropagation();
    }

    return (
        <>
            <h3>Available Caddies</h3>
            <h6 style={{color: "grey"}}>{moment(selectedDate).format('dddd Do MMMM')}</h6>
            <div id={"1"}>
                {smartphoneAvailableCaddies && smartphoneAvailableCaddies.map((c: UserAvailability) => {
                    return <div
                        draggable={!!c.caddieId}
                        onDragStart={(e) => {
                            if (!c.caddieId) {
                                return;
                            }
                            dragStart(e, c.caddieId)
                        }}
                        onDragOver={dragOver}>
                        {club && <CaddieAvailabilityCard date={selectedDate} clubId={club.id} userAvailability={c} />}
                    </div>
                })}
            </div>
            {!smartphoneAvailableCaddies && <Spinner animation='border' />}
            {smartphoneAvailableCaddies && smartphoneAvailableCaddies.length === 0 && <>
                <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', textAlign: 'center', marginTop: '2em' }}>
                    <div>
                        <Image src={'/ohno.png'} style={{ height: '15em' }} className="d-block mx-auto img-fluid" />
                    </div>
                    <p style={{ color: 'grey' }}>No caddies are available.</p>
                </div>
            </>}
        </>
    )
}

export default AvailableCaddiesDrawerContent;