import React, {useEffect, useState} from "react";
import {BookingsTable} from "../../../caddie/bookings/table/BookingsTable";
import {currentMonth, logErrorFromApi} from "../../../../services/Utils";
import {UserBooking} from "../../../../services/ApiDomain";
import {useApi} from "../../../../services/useApi";

interface Props {
    caddieId: string
    clubId: string
    clubName: string
}

export const ProfileBookings = ({caddieId, clubId, clubName}: Props) => {

    const { getBookingsForCaddieOnMonth } = useApi();

    const [selectedDate, setSelectedDate] = useState<string>(currentMonth());
    const [bookings, setBookings] = useState<UserBooking[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        loadBookings()
            .catch(err => logErrorFromApi(err));
    }, [caddieId, clubId, selectedDate]);

    const loadBookings = async () => {
        if (!caddieId || !clubId || !clubName) {
            return;
        }

        try {
            setLoading(true);
            let caddieMasterBookings = await getBookingsForCaddieOnMonth(clubId, caddieId, `${selectedDate}-01`);
            let asUserBookings: UserBooking[] = caddieMasterBookings.map(caddieMasterBooking => {
                return {
                    bookingId: caddieMasterBooking.id,
                    caddieId: caddieId,
                    clubId: clubId,
                    clubName: clubName,
                    teeTime: caddieMasterBooking.teeTime
                }
            });
            setBookings(asUserBookings);
        } finally {
            setLoading(false);
        }
    }

    return <>
        <div className="profileBookings_dateSelectionContainer">
            <span>Select the month you want to see caddie bookings for: </span>
            <input type="month"
                   defaultValue={selectedDate}
                   min={currentMonth()}
                   onChange={(e) => setSelectedDate(e.target.value)}/>

        </div>

        <BookingsTable bookings={bookings} loading={loading} />
    </>
}