import React, {useEffect, useState} from "react";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../services/store/store";
import useWindowSize from "../services/useWindowSize";
import {useApi} from "../services/useApi";
import {handicaddieSelector} from "../services/store/asyncThunk";
import {
    handleAddClubToManualCourseAllowed,
    handleAddClubToNoTeeTimes,
    handleDateChange,
    handleShowDeleteBookingModal
} from "../services/store/reducers/scheduler/slice";
import {dateWithoutTimezone} from "../services/store/reducers/scheduler/service";
import {Spinner} from "react-bootstrap";
import MobileCaddieMasterScheduler from "../parts/caddiemaster/scheduler/MobileCaddieMasterScheduler";
import DesktopCaddieMasterScheduler from "../parts/caddiemaster/scheduler/DesktopCaddieMasterScheduler";
import PublishScheduleModal from "../parts/caddiemaster/scheduler/publishschedulemodal/PublishScheduleModal";
import {reloadScheduler} from "../services/store/reducers/scheduler/asyncActions";
import DeleteBookingModal from "../parts/caddiemaster/scheduler/booking_modal/DeleteBookingModal";

interface Props {

}

const Scheduler = ({}: Props) => {

    let windowSize = useWindowSize();
    const { checkFeatureFlag } = useApi();

    const {
        selectedDate,
        timesheet,
        hasDraftBookings,
        showDeleteModal,
    } = handicaddieSelector(state => state.scheduler)
    const { selectedClub } = handicaddieSelector(state => state.clubs)
    const dispatch = useDispatch<AppDispatch>()

    const [showPublishScheduleModal, setShowPublishScheduleModal] = useState<boolean>(false);

    useEffect(() => {
        dispatch(handleDateChange(dateWithoutTimezone()))
    }, []);

    useEffect(() => {
        const checkIsNoTeeTimesAllowed = async () => {
            if (selectedClub.club) {
                const allowed = await checkFeatureFlag("NO_TEE_TIMES", selectedClub.club.id, "CLUB");
                if (allowed) {
                    dispatch(handleAddClubToNoTeeTimes(selectedClub.club.id))
                }
            }
        };

        const checkIsAddManualCourseAllowed = async () => {
            if (selectedClub.club) {
                const approved = await checkFeatureFlag("MANUAL_ENTER_COURSE", selectedClub.club.id, "CLUB");
                if (approved) {
                    dispatch(handleAddClubToManualCourseAllowed(selectedClub.club.id))
                }
            }
        };

        checkIsNoTeeTimesAllowed();
        checkIsAddManualCourseAllowed();
    }, [selectedClub]);

    return (
        <>
            {!windowSize || !windowSize.width || !selectedClub && <Spinner animation='border'/>}
            {windowSize.width && windowSize.width < 992 && selectedClub.club && <MobileCaddieMasterScheduler/>}
            {windowSize.width && windowSize.width > 992 && selectedClub.club &&
                <DesktopCaddieMasterScheduler
                    showPublishScheduleModal={(x: boolean) => {
                        setShowPublishScheduleModal(x && hasDraftBookings)
                    }}
                />}

            <PublishScheduleModal
                clubId={selectedClub.club?.id}
                selectedDate={new Date(selectedDate)}
                show={showPublishScheduleModal}
                refreshTimesheet={() => dispatch(reloadScheduler())}
                close={() => setShowPublishScheduleModal(false)}
                teeTimes={timesheet.teeTimes}
            />

            <DeleteBookingModal
                showDeleteBookingModal={showDeleteModal}
                handleDeleteBookingModalClose={() => dispatch(handleShowDeleteBookingModal({
                    show: false, bookingId: undefined
                }))} />
        </>
    )
}

export default Scheduler;