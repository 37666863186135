import {AppStartListening} from "../../store";
import {handleAddBookingFinished, handleDateChange, handleResetDrawer} from "./slice";
import {ListenerEffectAPI, PayloadAction, Unsubscribe} from "@reduxjs/toolkit";
import {addBooking, calculateTeeTimesFromTimesheet, loadTimesheet, reloadScheduler} from "./asyncActions";
import {TimesheetResource} from "../../../ApiDomain";
import {loadSelectedClub} from "../clubs/asyncActions";

const postHandleDateChange = async (_: PayloadAction<string>, listenerApi: ListenerEffectAPI<any, any>) => {
    listenerApi.dispatch(reloadScheduler())
}

const postHandleClubChange = async (_: any, listenerApi: ListenerEffectAPI<any, any>) => {
    listenerApi.dispatch(reloadScheduler())
    listenerApi.dispatch(handleResetDrawer())
}

const postLoadTimesheet = async (_: PayloadAction<TimesheetResource | undefined>, listenerApi: ListenerEffectAPI<any, any>) => {
    listenerApi.dispatch(calculateTeeTimesFromTimesheet())
}

const postBookingAdded = async (_: PayloadAction, listenerApi: ListenerEffectAPI<any, any>) => {
    listenerApi.dispatch(handleAddBookingFinished())
    listenerApi.dispatch(reloadScheduler())
}

export function setupSchedulerListeners(startListening: AppStartListening): Unsubscribe {
    const subscriptions = [
        startListening({
            actionCreator: handleDateChange,
            effect: postHandleDateChange,
        }),
        startListening({
            actionCreator: loadSelectedClub.fulfilled,
            effect: postHandleClubChange,
        }),
        startListening({
            actionCreator: loadTimesheet.fulfilled,
            effect: postLoadTimesheet,
        }),
        startListening({
            actionCreator: addBooking.fulfilled,
            effect: postBookingAdded,
        }),
    ]

    return () => {
        subscriptions.forEach((unsubscribe) => unsubscribe())
    }
}