import {configureStore, createListenerMiddleware, TypedStartListening} from "@reduxjs/toolkit";
import clubsReducer from './reducers/clubs/slice'
import currentUser from "./reducers/currentUser";
import schedulerReducer from "./reducers/scheduler/slice"

export const listenerMiddleware = createListenerMiddleware()

export const store = configureStore({
    reducer: {
        currentUser: currentUser,
        scheduler: schedulerReducer,
        clubs: clubsReducer,
    },
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().prepend(listenerMiddleware.middleware)
})

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

// NO IDEA WHAT'S GOING ON
// @see https://redux-toolkit.js.org/api/createListenerMiddleware#typescript-usage
export type AppStartListening = TypedStartListening<RootState, AppDispatch>
// export type AppAddListener = TypedAddListener<RootState, AppDispatch>

export const startAppListening = listenerMiddleware.startListening as AppStartListening
// export const addAppListener = addListener as AppAddListener

