import React from "react";
import {Table} from "react-bootstrap";

import {UserBooking} from "../../../../services/ApiDomain";

import './BookingsTable.scss';

interface Props {
    bookings: UserBooking[]
    loading: boolean
}

export const BookingsTable = ({bookings, loading}: Props) => {

    return (
        <Table borderless>
            <thead className="bookingsTable_header">
            <td>Date</td>
            <td>Time</td>
            <td>Location</td>
            </thead>
            <tbody className={"bookingsTable_bodyContainer"}>
            {!loading && bookings && bookings.map(booking => {
                return <tr>
                    <td>{new Date(booking.teeTime).toLocaleString('gb', {
                        weekday: 'long',
                        year: 'numeric',
                        month: 'long',
                        day: 'numeric'
                    })}</td>
                    <td>
                        {new Date(booking.teeTime).toLocaleString('gb', {
                            hour: 'numeric',
                            minute: 'numeric',
                            hour12: false
                        })}
                    </td>
                    <td>{booking.clubName}</td>
                </tr>
            })}
            {!loading && bookings && bookings.length === 0 && <tr>
                <td>No bookings.</td>
                <td />
                <td />
            </tr>}
            {loading && <tr>
                <td>Loading...</td>
                <td />
                <td />
            </tr>}
            </tbody>
        </Table>
    )

}
