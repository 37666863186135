import React, {useEffect, useState} from 'react';
import {Alert, Button, Form, Spinner} from "react-bootstrap";
import {DeleteOutline, Person} from "@material-ui/icons";
import SlotCountIndicator from "../../components/SlotCountIndicator";
import {ClubBooking, ClubBookingSlot, SlotType} from "../../../../../services/ApiDomain";
import moment from "moment";
import AvailableCaddiesForBooking from "../components/AvailableCaddiesForBooking";
import {toast} from "react-toastify";
import {TimeSelector} from '../../../../../components/time-selctor/TimeSelector';
import {CourseCreationAndSelection} from '../components/CourseCreationAndSelection';
import CaddieBadge from "../../components/CaddieBadge";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../../services/store/store";
import {handleDrawerChange, handleShowDeleteBookingModal} from "../../../../../services/store/reducers/scheduler/slice";
import {Drawer} from "../../../../../services/store/reducers/scheduler/domain";
import {handicaddieSelector} from "../../../../../services/store/asyncThunk";
import {
    calculateNumberOfTeeTimesInPool,
    calculateSlotsWithNewCount
} from "../../../../../services/store/reducers/scheduler/service";
import {addBooking} from "../../../../../services/store/reducers/scheduler/asyncActions";

interface Props {
}

const BookingDrawerContent = ({}: Props) => {

    const [bookingId, setBookingId] = useState<string | undefined>()
    const [teeTime, setTeeTime] = useState<string | undefined>()
    const [endTeeTime, setEndTeeTime] = useState<string | undefined>()
    const [title, setTitle] = useState<string | undefined>()
    const [courseId, setCourseId] = useState<string | undefined>()
    const [notes, setNotes] = useState<string | undefined>()
    const [shareNote, setShareNote] = useState<boolean>(false)
    const [isBookingPool, setIsBookingPool] = useState<boolean>(false)
    const [teeTimesInPool, setTeeTimesInPool] = useState<number>(0)
    const [bookingSlots, setBookingSlots] = useState<ClubBookingSlot[]>([])
    const [nonEmptyBookingSlots, setNonEmptyBookings] = useState<ClubBookingSlot[]>([])
    const [isNoTeeTimeBooking, setIsNoTeeTimeBooking] = useState<boolean>(false)

    const [caddieSearchTerm, setCaddieSearchTerm] = useState<string>()

    const {
        addBookingLoading,
        selectedDate,
        areNoTeeTimesAllowed,
        isManualCourseAllowed,
        selectedTeeTime,
        teeTimes,
        timesheet,
    } = handicaddieSelector(state => state.scheduler)
    const {
        selectedClub,
    } = handicaddieSelector(state => state.clubs)
    const dispatch = useDispatch<AppDispatch>()

    useEffect(() => {
        resetForm()
    }, [selectedTeeTime])

    useEffect(() => {
        setNonEmptyBookings(bookingSlots.filter(slot => slot.caddieId || slot.slotTypeId))
    }, [bookingSlots]);

    const resetForm = () => {
        if (!selectedTeeTime) {
            return;
        }

        if (selectedTeeTime.course) {
            setCourseId(selectedTeeTime.course.id)
        } else {
            setCourseId(undefined)
        }

        setBookingId(undefined)
        setEndTeeTime(undefined)
        setTitle("")
        setIsBookingPool(false)
        setNotes("")
        setShareNote(false)
        setBookingSlots([])
        setIsNoTeeTimeBooking(false)

        if (selectedTeeTime && selectedTeeTime.loadingBooking) {
            return;
        }

        setTeeTime(selectedTeeTime.teeTime)

        if (selectedTeeTime.booking) {
            let b: ClubBooking = selectedTeeTime.booking
            setIsNoTeeTimeBooking(moment(selectedTeeTime.booking?.teeTime).format('HH:mm') === '02:02')
            setBookingId(b.bookingId)
            setEndTeeTime(b.endTeeTime)
            setTitle(b.title)
            setCourseId(b.courseId)
            setNotes(b.notes)
            setShareNote(b.shareNote)
            setBookingSlots(b.bookingSlots)
            if (b.endTeeTime) {
                setIsBookingPool(true)
                setTeeTimesInPool(selectedTeeTime?.teeTimesInPool ? selectedTeeTime.teeTimesInPool : 1)
            }
        }
    }

    const updateSlotCount = (x: number) => {
        let resp = calculateSlotsWithNewCount(bookingSlots, x)
        if (resp.error && resp.error === 'TOO_MANY_NON_EMPTY_SLOTS') {
            toast.error("Your booking contains slots with caddies or slot types, please remove these first before reducing the number of caddies.")
            return;
        }
        setBookingSlots(resp.slots)
    }

    const updateBooking = () => {
        if (!selectedClub.club || !selectedTeeTime) {
            return;
        }

        // check tee time is valid...
        if (!teeTime && areNoTeeTimesAllowed.includes(selectedClub.club.id)) {
            setTeeTime('02:02')
            return;
        } else if (!teeTime) {
            toast.error('Please input a tee time.')
            return
        } else if (selectedClub.club.hasTimesheet && teeTime && selectedTeeTime.isSqueezedOrNoTeeTimeBooking) {
            const isExist = teeTimes?.includes(teeTime)
            if (isExist) {
                toast.warn("Selected tee time already exists. Please select a different tee time.");
                return
            }
        }

        // check course is valid
        if (selectedClub.courses.length !== 0 && !selectedTeeTime?.course && !courseId) {
            toast.error('Please select or add a course for booking.')
            return
        }

        dispatch(addBooking({
            bookingId: bookingId,
            bookingSlots: bookingSlots,
            courseId: courseId ? courseId : selectedTeeTime.course?.id,
            endTeeTime: endTeeTime,
            notes: notes,
            shareNote: shareNote,
            teeTime: teeTime,
            title: title,
        }))
    }

    const onCourseChange = (courseId: string, teeTime?: string) => {
        if (!teeTime) {
            setCourseId(courseId)
            return;
        }

        let courseHasTeeTime = timesheet.teeTimes
            .filter(resource => resource.courseId === courseId)
            .map(resource => resource.teeTime)
            .includes(teeTime)

        if (!selectedTeeTime?.isSqueezedOrNoTeeTimeBooking && !courseHasTeeTime) {
            toast.error("This tee time does not exist on this course.")
            return;
        }

        setCourseId(courseId)
    }

    const resetToNonBookingPool = () => {
        if (bookingSlots.filter(slot => slot.caddieId || slot.slotTypeId).length > 4) {
            toast.error("Reduce the number of slots to below 4 before switching the booking type.")
            return;
        }

        setBookingSlots(bookingSlots.filter(slot => slot.caddieId || slot.slotTypeId))
        setIsBookingPool(false);
        setEndTeeTime(undefined)
    }

    const calculateMaxCaddies = () => {
        return Math.max(1, (teeTimesInPool ? teeTimesInPool : 1)) * 4
    }

    const drop = async (e: React.DragEvent) => {
        e.preventDefault();
    }

    const addCaddie = (userId: string, caddieId: string, name: string, noSmartphone: boolean) => {
        const now = moment().format("YYYY-MM-DD");
        const bookingTeeTime = moment(teeTime || selectedDate).format("YYYY-MM-DD");
        const slotStatus = now === bookingTeeTime ? "PENDING" : "DRAFT"

        let caddieAdded = false
        setBookingSlots((state) =>
            state.map((slot) => {
                if (caddieAdded) {
                    return slot
                }
                if (!slot.caddieId) {
                    caddieAdded = true
                    return {
                        ...slot,
                        caddieId: caddieId,
                        userId: userId,
                        displayName: name,
                        noSmartphone: noSmartphone,
                        status: slotStatus
                    }
                }
                return slot
            }));
    }

    const removeCaddie = (caddieId?: string) => {
        if (!caddieId) {
            toast.error('No caddie information found to remove from the slot.');
            return;
        }

        setBookingSlots((state) =>
            state.map((slot) => {
                if (slot.caddieId === caddieId) {
                    return {
                        ...slot,
                        caddieId: undefined,
                        userId: undefined,
                        noSmartphone: false,
                        status: "OPEN"
                    }
                }
                return slot;
            }));
    }

    const updateStatusOfBookingSlot = (index: number, status: string) => {
        setBookingSlots((state) =>
            state.map((slot, i) => {
                if (index === i) {
                    return {
                        ...slot,
                        status: status
                    }
                }
                return slot;
            }));
    }

    const updateSlotTypeOfBookingSlot = (index: number, slotType?: SlotType, removeSelectedSlotType?: boolean) => {
        setBookingSlots((state) =>
            state.map((slot, i) => {
                if (index === i) {
                    if (removeSelectedSlotType) {
                        return {
                            ...slot,
                            slotTypeId: undefined,
                            slotTypeTitle: undefined
                        }
                    } else if (slotType) {
                        return {
                            ...slot,
                            slotTypeId: slotType?.id,
                            slotTypeTitle: slotType?.emoji + " " + slotType?.title
                        }
                    }
                }
                return slot;
            }));
    }

    const deleteBookingSlot = (index: number) => {
        setBookingSlots(bookingSlots.filter((_, i) => i !== index));
    }

    let slotButtonStyle: string = "scheduler_bookingTypeButton_disabled"
    let groupButtonStyle: string = "scheduler_bookingTypeButton_disabled"

    if (bookingId) {
        if (endTeeTime) {
            groupButtonStyle = "scheduler_bookingTypeButton_active"
        } else {
            slotButtonStyle = "scheduler_bookingTypeButton_active"
        }
    } else {
        if (isBookingPool) {
            slotButtonStyle = "scheduler_bookingTypeButton_inactive"
            groupButtonStyle = "scheduler_bookingTypeButton_active"
        } else {
            slotButtonStyle = "scheduler_bookingTypeButton_active"
            groupButtonStyle = "scheduler_bookingTypeButton_inactive"
        }
    }

    return (
        <>
            {(!selectedTeeTime) && 'Loading...'}
            {(selectedTeeTime) && <>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    marginBottom: '1em'
                }}>
                    <div>
                        {(selectedTeeTime.isSqueezedOrNoTeeTimeBooking || isNoTeeTimeBooking) &&
                            <h3>{selectedClub.club?.hasTimesheet ? "Squeeze a Tee Time" : "Add a Booking"}</h3>}
                        {!selectedTeeTime.isSqueezedOrNoTeeTimeBooking && !isNoTeeTimeBooking &&
                            <h3>{teeTime}</h3>}
                        <h6 style={{color: "gray"}}>
                            {moment(new Date(selectedDate).toDateString()).format('dddd Do MMMM')}
                        </h6>
                    </div>
                    <div>
                        <div style={{
                            height: '2em',
                            width: '2em',
                            backgroundColor: bookingId ? '#b72323' : '#ccc',
                            textAlign: 'center',
                            cursor: 'pointer',
                        }}
                             onClick={() => dispatch(handleShowDeleteBookingModal({
                                 show: true,
                                 bookingId: bookingId
                             }))}>
                            <DeleteOutline style={{color: 'white', verticalAlign: 'text-top'}}/>
                        </div>
                    </div>
                </div>

                {(!selectedTeeTime.isEditing && !selectedTeeTime.isSqueezedOrNoTeeTimeBooking) &&
                    <div style={{marginBottom: '2em', display: 'flex', flexDirection: 'row'}}>
                        <div style={{
                            textAlign: 'center',
                            cursor: 'pointer',
                            width: 'fit-content',
                            marginRight: '1em',
                            padding: '0.25rem 1.5rem',
                            borderRadius: '1em',
                        }}
                             className={slotButtonStyle}
                             onClick={resetToNonBookingPool}
                        >
                            <span style={{fontWeight: 500, fontSize: '0.75em'}}>ADD CADDIES TO SLOT</span>
                        </div>
                        <div style={{
                            textAlign: 'center',
                            cursor: 'pointer',
                            width: 'fit-content',
                            padding: '0.25rem 1.5rem',
                            borderRadius: '1em'
                        }}
                             className={groupButtonStyle}
                             onClick={() => setIsBookingPool(true)}
                        >
                            <span style={{fontWeight: 500, fontSize: '0.75em'}}>CREATE CADDIE POOL</span>
                        </div>
                    </div>
                }

                {isBookingPool && !selectedTeeTime?.isSqueezedOrNoTeeTimeBooking && <div style={{marginBottom: '2em'}}>
                    <h6>Pool Tee Times</h6>
                    <Alert variant="primary">Your caddies will receive booking requests for: {teeTime}.</Alert>

                    <div style={{display: 'flex', flexDirection: 'row', width: '60%', justifyContent: 'space-between'}}>
                        <div>
                            <span>From: </span>
                            <span>{teeTime}</span>
                        </div>
                        <div>
                            <span>To: </span>
                            {selectedTeeTime.isEditing && endTeeTime && <span>{moment(endTeeTime).format('HH:mm')}</span>}
                            {!selectedTeeTime.isEditing &&
                                <select value={endTeeTime ? endTeeTime : teeTime}
                                        style={{border: 'none'}}
                                        onChange={(e) => {
                                            let selectedTeeTime = e.target.value
                                            setEndTeeTime(selectedTeeTime)
                                            setTeeTimesInPool(calculateNumberOfTeeTimesInPool(teeTime, selectedTeeTime, teeTimes))
                                        }}>
                                    {teeTimes?.map(time => {
                                        if (teeTime && time < teeTime) {
                                            return;
                                        }
                                        if (selectedTeeTime.isEditing && endTeeTime && time === teeTime) {
                                            return;
                                        }
                                        return <option key={time} value={time}>{time}</option>
                                    })}
                                </select>}

                        </div>
                    </div>
                </div>}

                {(selectedTeeTime.isSqueezedOrNoTeeTimeBooking || isNoTeeTimeBooking) &&
                    <div style={{marginBottom: '2em'}}>
                        <TimeSelector value={teeTime === '02:02' ? "" : teeTime} onTimeChange={setTeeTime}/>
                    </div>
                }

                <div style={{marginBottom: '2em'}}>
                    <h6>Number of Caddie Slots</h6>
                    {!isBookingPool && <SlotCountIndicator count={bookingSlots.length} onClick={updateSlotCount}/>}
                    {isBookingPool && <select onChange={(e) => updateSlotCount(Number.parseFloat(e.target.value))}
                                              value={bookingSlots.length}>
                        {[...Array(calculateMaxCaddies())].map((i, idx) => {
                            let count = idx + 1
                            return <option
                                selected={selectedTeeTime?.isEditing && count === bookingSlots.length}
                                key={count}
                                value={count}>{count}</option>
                        })}
                    </select>
                    }
                </div>

                {selectedClub.club && isManualCourseAllowed.includes(selectedClub.club.id) &&
                    <CourseCreationAndSelection
                        teeTime={selectedTeeTime?.teeTime}
                        onSelectCourse={setCourseId}
                        clickedTimesheetRowCourseId={courseId}
                        disable={!!selectedTeeTime?.booking?.bookingId}
                    />}

                {selectedClub.club &&
                    !isManualCourseAllowed.includes(selectedClub.club.id) &&
                    selectedClub.courses &&
                    (selectedClub.hasMultipleCourses || selectedTeeTime?.isSqueezedOrNoTeeTimeBooking) &&
                    <div style={{marginBottom: '2em'}}>
                        <h6>Course</h6>
                        <Form.Control as="select"
                                      disabled={selectedTeeTime?.isEditing}
                                      onChange={event => onCourseChange(event.target.value, teeTime)}>
                            <option disabled selected>Select Course</option>
                            {selectedClub.courses.map(c => <option key={c.id} value={c.id}
                                                                   selected={c.id === courseId}>{c.name}</option>)}
                        </Form.Control>
                    </div>
                }

                <div style={{marginBottom: '2em'}}>
                    <h6>Title</h6>
                    <Form.Control
                        type={"text"}
                        value={title}
                        onChange={(e) => setTitle(e.target.value)}/>
                </div>

                {bookingSlots.length > 0 && <div style={{marginBottom: '2em'}}>
                    <div style={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'space-between',
                        marginBottom: '0.5em'
                    }}>
                        <h6>Select Caddies</h6>
                        <div>
                            <Person/>
                            <span>{nonEmptyBookingSlots.length}/{bookingSlots.length > 0 ? bookingSlots.length : 4}</span>
                        </div>
                    </div>

                    <div style={{width: '100%', display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        {bookingSlots.map((slot, index) => {
                            return <CaddieBadge
                                key={`bookingSlot-${index}`}
                                clubId={selectedClub.club?.id}
                                bookingId={bookingId}
                                onDrop={(e: React.DragEvent) => drop(e)}
                                bookingSlot={slot}
                                isFromBookingSidePanel={true}
                                removeCaddieFromBookingSlot={() => removeCaddie(slot.caddieId)}
                                updateStatusOfBookingSlot={(status: string) => updateStatusOfBookingSlot(index, status)}
                                updateSlotTypeOfBookingSlot={(slotType?: SlotType, removeSelectedSlotType?: boolean) => updateSlotTypeOfBookingSlot(index, slotType, removeSelectedSlotType)}
                                onDelete={() => deleteBookingSlot(index)}
                            />
                        })}
                    </div>
                </div>}

                <div style={{marginBottom: '2em'}}>
                    <div style={{
                        width: '100%',
                        marginBottom: '1em',
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'wrap',
                    }}>
                        <h6>Your Available Caddies</h6>
                        <Form.Control
                            type={"text"}
                            placeholder="Search for caddie..."
                            style={{marginBottom: '0.5em'}}
                            onChange={e => setCaddieSearchTerm(e.target.value)}/>
                        <AvailableCaddiesForBooking
                            addCaddieToBooking={addCaddie}
                            selectedCaddies={bookingSlots.filter(slot => slot.caddieId).map((slot) => slot.caddieId)}
                            canAddCaddies={bookingSlots.length > 0 ?
                                bookingSlots.length > bookingSlots.filter(slot => slot.caddieId).length :
                                4 > bookingSlots.filter(slot => slot.caddieId).length}
                            teeTime={selectedTeeTime.teeTime}
                            searchTerm={caddieSearchTerm}/>
                    </div>
                </div>

                <div style={{marginBottom: '2em'}}>
                    <h6 style={{marginBottom: 0}}>Notes</h6>
                    <Form.Check
                        checked={shareNote}
                        onChange={(e) => setShareNote(e.target.checked)}
                        label={<span><i>Share this note with caddies</i></span>}/>
                    <Form.Text muted>
                        No smartphone caddies will not have the note shared with them.
                    </Form.Text>
                    <Form.Control as="textarea" rows={5} value={notes} style={{marginTop: '0.5em'}}
                                  onChange={(e) => setNotes(e.target.value)}/>
                </div>

                <div style={{borderTop: '1px solid #ccc', paddingTop: '1em'}}>
                    <div style={{
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        marginBottom: '1em',
                        paddingBottom: '0.75em',
                    }}>
                        {addBookingLoading && <Spinner animation='border'/>}
                        <Button variant='outline-danger'
                                style={{marginRight: '0.5em'}}
                                onClick={() => dispatch(handleDrawerChange(Drawer.AVAILABLE_CADDIES))}
                                disabled={addBookingLoading}>Cancel</Button>
                        <Button onClick={updateBooking} disabled={addBookingLoading}>UPDATE</Button>
                    </div>
                </div>
            </>
            }
        </>
    )
}

export default BookingDrawerContent;