import React from 'react';
import {Button, Modal} from "react-bootstrap";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../../../services/store/store";
import {deleteBooking} from "../../../../services/store/reducers/scheduler/asyncActions";

type DeleteBookingModalProps = {
    bookingId?: string
    showDeleteBookingModal: boolean
    handleDeleteBookingModalClose: () => void
}

const DeleteBookingModal = ({bookingId, showDeleteBookingModal, handleDeleteBookingModalClose}: DeleteBookingModalProps) => {

    const dispatch = useDispatch<AppDispatch>()

    const deleteExistingBooking = () => {
        dispatch(deleteBooking())
    }

    return (
        <Modal show={showDeleteBookingModal} onHide={handleDeleteBookingModalClose}>
            <Modal.Header closeButton>
                <Modal.Title>Confirm Delete Booking</Modal.Title>
            </Modal.Header>

            <Modal.Body>
                <p>Are you sure you want to delete this booking?</p>
            </Modal.Body>

            <Modal.Footer>
                <Button variant="outline-primary" onClick={handleDeleteBookingModalClose}>Close</Button>
                <Button variant="danger" onClick={deleteExistingBooking}>Delete</Button>
            </Modal.Footer>
        </Modal>
    )
}

export default DeleteBookingModal;