import ApiClient from "../ApiClient";
import {
    AnalyticsResource,
    Booking,
    BookingSlot,
    CaddieAvailability,
    CaddieMaster,
    CaddieResource,
    Club,
    ClubBooking,
    ClubCaddie,
    Course,
    FeedItem,
    MembershipDomain,
    PagedApiResponse,
    Session,
    SlotType,
    TaskRequestResource,
    TaskResource,
    TimesheetResource,
    Tour,
    TourCaddie,
    TourCaddieApplicationAnswer,
    TourCaddieApplicationSummary,
    TourCaddieResource,
    TourMaster,
    User,
    UserAvailability,
    UserBooking,
    UserCaddie,
    UserDetails
} from "./ApiDomain";
import moment from "moment";

export const useApi = () => {

    const getCurrentUser = () =>
        ApiClient.getInstance().get<User>(`/user`);

    const registerUser = (email: string, password: string) =>
        ApiClient.getInstance().post<User>(`/credentials`, {}, {
            withCredentials: true,
            headers: {
                Authorization: 'Basic ' + btoa(email + ':' + password)
            }
        });

    const confirmEmail = (email: string, password: string, code: string) =>
        ApiClient.getInstance().put<User>(`/confirmation?confirmationCode=${code}`, {}, {
            withCredentials: true,
            headers: {
                Authorization: 'Basic ' + btoa(email + ':' + password)
            }
        });

    const resendConfirmation = (email: string, password: string) =>
        ApiClient.getInstance().delete<void>(`/confirmation`, {
            withCredentials: true,
            headers: {
                Authorization: 'Basic ' + btoa(email + ':' + password)
            }
        });

    const forgotPassword = (email: string) =>
        ApiClient.getInstance().delete<void>(`/credentials`, {
            data: {
                email
            }
        });

    const updateForgotPassword = (email: string, password: string, code: string) =>
        ApiClient.getInstance().put<void>(`/credentials?confirmationCode=${code}`, {}, {
            withCredentials: true,
            headers: {
                Authorization: 'Basic ' + btoa(email + ':' + password)
            }
        });

    const loginUser = (email: string, password: string) =>
        ApiClient.getInstance().post<Session>(`/session`, {}, {
            withCredentials: true,
            headers: {
                Authorization: 'Basic ' + btoa(email + ':' + password)
            }
        });

    const createUser = (firstName: string, lastName: string, email: string) =>
        ApiClient.getInstance().post<User>(`/user`, {
            firstName: firstName,
            lastName: lastName,
            email: email
        });

    const getUser = (userId: string) =>
        ApiClient.getInstance().get<User>(`/user/${userId}`);

    const updateCurrentUser = (userId: string, details: UserDetails) =>
        ApiClient.getInstance().put<UserDetails>(`/user/${userId}`, {
            ...details
        });

    const uploadAvatar = (uuid: string) => {
        return ApiClient.getInstance().put<string>(`/user/${uuid}/avatar/new`)
    };

    const getAvatar = (userId: string | undefined) =>
        ApiClient.getInstance().get<string>(`/user/${userId}/avatar/new`);

    const getCaddieMastersForUser = (userId: string) =>
        ApiClient.getInstance().get<CaddieMaster[]>(`/caddiemasters?forUser=${userId}`);

    const getCaddiesForUser = (userId: string) =>
        ApiClient.getInstance().get<CaddieResource[]>(`/caddies?forUser=${userId}`);

    const getTourMastersForUser = () =>
        ApiClient.getInstance().get<TourMaster[]>(`/tourmasters`);

    const getTourCaddiesForUser = (userId: string) =>
        ApiClient.getInstance().get<TourCaddieResource[]>(`/tourcaddies`);

    const getBookings = (userId: string) =>
        ApiClient.getInstance().get<UserBooking[]>(`user/${userId}/bookings`);

    const getMemberships = () =>
        ApiClient.getInstance().get<MembershipDomain[]>(`memberships`);

    const getHomeFeed = (userId: string) =>
        ApiClient.getInstance().get<Map<string, FeedItem[]>>(`feed/${userId}`);

    const getCaddieAvailabilities = (caddieId: string) =>
        ApiClient.getInstance().get<CaddieAvailability[]>(`/caddie/${caddieId}/availabilities`);

    const getCaddieAvailabilitiesForUser = (userId: string) =>
        ApiClient.getInstance().get<CaddieAvailability[]>(`/user/${userId}/availabilities`);

    const updateCaddieAvailabilities = (caddieId: string, dates: string[]) =>
        ApiClient.getInstance().post<CaddieAvailability[]>(`/caddie/${caddieId}/availabilities`, {
            availabilities: dates
        });

    const createCaddie = (userId: string, clubId: string) =>
        ApiClient.getInstance().post<any>(`/caddie`, { userId: userId, clubId: clubId});

    const getCaddie = (clubId:string, id: string) =>
        ApiClient.getInstance().get<ClubCaddie>(`/club/${clubId}/caddie/${id}`);

    const getCaddies = (userId: string) =>
        ApiClient.getInstance().get<UserCaddie[]>(`user/${userId}/caddies`);

    const getBookingsForCaddieOnMonth = (clubId: string, caddieId: string, month: string) =>
        ApiClient.getInstance().get<Booking[]>(`/club/${clubId}/caddie/${caddieId}/bookings?forMonth=${month}`);

    const getPendingCaddies = (userId: string) =>
        ApiClient.getInstance().get<UserCaddie[]>(`user/${userId}/caddies?onlyPending=true`);

    const getNoSmartphoneCaddies = (userId: string) =>
        ApiClient.getInstance().get<UserCaddie[]>(`user/${userId}/caddies?onlyNonSmartphone=true`);

    const approveCaddie = (clubId: string, id: string) =>
        ApiClient.getInstance().post<void>(`/club/${clubId}/caddie/${id}`);

    const deleteCaddie = (id: string) =>
        ApiClient.getInstance().delete<void>(`/club-caddie/${id}`);

    const createClubCaddie = (email: string, firstName: string, lastName: string, phoneNumber: string, nonSmartphone: boolean) =>
        ApiClient.getInstance().post<void>(`/club-caddie`, {
            email, firstName, lastName, phoneNumber, nonSmartphone
        });

    const denyCaddie = (clubId: string, id: string) =>
        ApiClient.getInstance().delete<void>(`/club/${clubId}/caddie/${id}`);

    const getCaddiesForClub = (clubId: string) =>
        ApiClient.getInstance().get<ClubCaddie[]>(`/club/${clubId}/caddies`);

    const getCurrentAvailableCaddiesForClub = (clubId: string) =>
        ApiClient.getInstance().get<PagedApiResponse<UserAvailability[]>>(`/club-availabilities?forClub=${clubId}`);

    const getAvailableCaddiesForClubOnDate = (clubId: string, date: Date) => {
        let d = moment(date).format('YYYY-MM-DDT16:00')
        return ApiClient.getInstance().get<PagedApiResponse<UserAvailability[]>>(`/club-availabilities?forClub=${clubId}&forDate=${d}&checkWindows=false`);
    }

    const getAvailableCaddiesForBooking = (clubId: string, date: string) =>
        ApiClient.getInstance().get<PagedApiResponse<UserAvailability[]>>(`/club-availabilities?forDate=${date}&forClub=${clubId}&size=100`);

    const getClub = (clubId: string) =>
        ApiClient.getInstance().get<Club>(`club/${clubId}`);

    const updateClub = (clubId: string, club: Club) =>
        ApiClient.getInstance().put<Club>(`club/${clubId}`, club);

    const getListOfClubs = () =>
        ApiClient.getInstance().get<Club[]>(`/clubs`);

    const createClub = (name: string) =>
        ApiClient.getInstance().post<Club>(`club/`, {
            name
        });

    const getClubCourses = (clubId: string) => ApiClient.getInstance().get<Course[]>(`/clubs/${clubId}/courses`)

    const getCoursesByClubIdAndSearchTerm = (clubId: string, searchTerm: string | undefined) =>
        ApiClient.getInstance().get<Course[]>(`/courses?forClubId=${clubId}&forSearchTerm=${searchTerm}`)

    const createClubCourse = (clubId: string, name: string, colourCode?: string) =>
        ApiClient.getInstance().post(`/clubs/${clubId}/courses`, {
            name,
            colourCode,
        });

    const getTimesheet = (clubId: string, date?: string, returnOnlyUpcomingBookings?: boolean) => {
        let apiUrl = `clubs/${clubId}/timesheet`;
        if (date) {
            let m = moment(date);
            let forDate = m.format('YYYY-MM-DD');
            apiUrl += `?forDate=${forDate}`;
        } else if (returnOnlyUpcomingBookings) {
            apiUrl += `?returnOnlyUpcomingBookings=${returnOnlyUpcomingBookings}`;
        }
        return ApiClient.getInstance().get<TimesheetResource>(apiUrl);
    }

    const getTasks = (clubId: string) => ApiClient.getInstance().get<TaskResource[]>(`clubs/${clubId}/tasks`);

    const updateTasks = (clubId: string, taskRequestResources: TaskRequestResource[]) =>
        ApiClient.getInstance().put(`clubs/${clubId}/tasks`, taskRequestResources);

    const getAnalytics = (clubId: string, type: string, metric: string, fromDate: string, toDate: string) =>
        ApiClient.getInstance().get<AnalyticsResource>(`clubs/${clubId}/analytics?type=${type}&metric=${metric}&fromDate=${fromDate}&toDate=${toDate}`);

    const getSchedule = (clubId: string, fromDate: string) =>
        ApiClient.getInstance().get<Map<string, ClubBooking[]>>(`/club/${clubId}/schedule?forDate=${fromDate}`);

    const getBooking = (id: string) =>
        ApiClient.getInstance().get<Booking>(`/booking/${id}`);

    const createBooking = (clubId: string, teeTime: string, title?: string, notes?: string, shareNote?: boolean, courseId?: string, endTeeTime?: string) => {
        return ApiClient.getInstance().post<Booking>(`/booking`, {
            clubId: clubId,
            courseId: courseId,
            title: title,
            notes: notes ? notes : '',
            teeTime: teeTime,
            endTeeTime: endTeeTime,
            shareNote: shareNote,
        });
    }

    const updateBooking = (id: string, title?: string, notes?: string, shareNote?: boolean, teeTime?: string) =>
        ApiClient.getInstance().put(`/booking/${id}`, {
            title: title,
            notes: notes,
            shareNote: shareNote,
            teeTime: teeTime
        });

    const deleteBooking = (id: string) =>
        ApiClient.getInstance().delete(`/booking/${id}`);

    const createPublicSlots = (bookingId: string) =>
        ApiClient.getInstance().post(`/public-slots`, {
            bookingId: bookingId
        })

    const createAnnouncement = (audienceId: string, type: string, busyDayDate: string) =>
        ApiClient.getInstance().post(`/announcement`, {
            audienceId: audienceId,
            type: type,
            busyDayDate: busyDayDate
        })

    const getTours = () =>
        ApiClient.getInstance().get<Tour[]>(`/tours`);

    const getTourCaddies = (tourId: string) =>
        ApiClient.getInstance().get<TourCaddie[]>(`/tour/${tourId}/tourcaddies`);

    const approveTourApplication = (tourId: string, applicationId: string) =>
        ApiClient.getInstance().put(`/tour/${tourId}/application/${applicationId}`);

    const disapproveTourApplication = (tourId: string, applicationId: string) =>
        ApiClient.getInstance().delete(`/tour/${tourId}/application/${applicationId}`);

    const tourCaddieApplication = (tourId: string, application: TourCaddieApplicationAnswer[]) =>
        ApiClient.getInstance().post(`/tour/${tourId}/application`, application);

    const getTourCaddieApplications = (tourId: string) =>
        ApiClient.getInstance().get<TourCaddieApplicationSummary[]>(`/tour/${tourId}/applications`);

    const getTourCaddieApplicationAnswers = (tourId: string, applicationId: string) =>
        ApiClient.getInstance().get<TourCaddieApplicationAnswer[]>(`/tour/${tourId}/application/${applicationId}/answers`);

    const  checkFeatureFlag =  (flagType: string, actorId: string="xxxxxxxx-xxxx-xxxx-xxxx-xxxxxxxxxxxx", actorType: string) =>
        ApiClient.getInstance().get<boolean>(`feature-flag?forFlag=${flagType}&forActor=${actorId}&forActorType=${actorType}`);  

    const createSlotType = (clubId: string, title: string, emoji?: string) => {
        return ApiClient.getInstance().post(`/slot-type`, { clubId, title, emoji });
    }

    const updateSlotType = (id: string, slotType: SlotType) => {
        return ApiClient.getInstance().put(`/slot-type/${id}`, slotType);
    }

    const deleteSlotType = (id: string) => {
        return ApiClient.getInstance().delete(`/slot-type/${id}`);
    }

    const getSlotTypes = (clubId: string) => {
        return ApiClient.getInstance().get<SlotType[]>(`slot-type/club/${clubId}`);
    }

    const getBookingSlots = (bookingId: string) => {
        return ApiClient.getInstance().get<BookingSlot[]>(`/bookings/${bookingId}/slots`);
    }

    const createBookingSlots = (bookingId: string, bookingSlots: BookingSlot[]) => {
        return ApiClient.getInstance().post(`/bookings/${bookingId}/slots`, bookingSlots);
    }

    const updateBookingSlot = (slot: BookingSlot) => {
        return ApiClient.getInstance().put(`/bookings/${slot.bookingId}/slots/${slot.id}`, slot);
    }

    const deleteBookingSlot = (bookingId: string, bookingSlotId: string) => {
        return ApiClient.getInstance().delete(`/bookings/${bookingId}/slots/${bookingSlotId}`);
    }

    return {
        getCurrentUser,
        registerUser,
        confirmEmail,
        resendConfirmation,
        forgotPassword,
        updateForgotPassword,
        loginUser,
        createUser,
        getUser,
        updateCurrentUser,
        uploadAvatar,
        getAvatar,

        getCaddieMastersForUser,
        getCaddiesForUser,

        getBookings,

        getMemberships,

        getHomeFeed,

        getCaddieAvailabilitiesForUser,
        getCaddieAvailabilities,
        updateCaddieAvailabilities,

        createCaddie,
        getCaddie,
        getCaddies,
        getBookingsForCaddieOnMonth,
        getPendingCaddies,
        getNoSmartphoneCaddies,
        createClubCaddie,
        approveCaddie,
        denyCaddie,
        deleteCaddie,
        getCaddiesForClub,
        getAvailableCaddiesForClubOnDate,
        getCurrentAvailableCaddiesForClub,
        getAvailableCaddiesForBooking,

        getTourMastersForUser,
        getTourCaddiesForUser,

        getClub,
        updateClub,
        getListOfClubs,
        createClub,

        getClubCourses,
        getCoursesByClubIdAndSearchTerm,
        createClubCourse,
        getTimesheet,

        getTasks,
        updateTasks,
        getAnalytics,

        getSchedule,
        getBooking,
        createBooking,
        updateBooking,
        deleteBooking,

        getTours,
        getTourCaddies,
        tourCaddieApplication,
        getTourCaddieApplications,
        getTourCaddieApplicationAnswers,
        approveTourApplication,
        disapproveTourApplication,
        createPublicSlots,
        createAnnouncement,

        checkFeatureFlag,

        createSlotType,
        updateSlotType,
        deleteSlotType,
        getSlotTypes,

        createBookingSlots,
        updateBookingSlot,
        deleteBookingSlot,
        getBookingSlots,

    }

}