import React from 'react';
import {Button, Card} from "react-bootstrap";
import {toast} from "react-toastify";
import CaddieCard from "../../../../components/club/caddie_card/CaddieCard";
import {useApi} from "../../../../services/useApi";

type Props = {
    clubId: string
    clubName: string
    name: string
    id: string
    userId: string
    refreshCaddies: () => void
};

export const PendingCaddieCard = ({name, clubId, clubName, id, userId, refreshCaddies}: Props) => {

    const { approveCaddie, denyCaddie } = useApi();

    const approve = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        approveCaddie(clubId, id)
            .then(async (r) => {
                refreshCaddies();
                toast.success(`${name} approved.`);
            })
            .catch(async (r) => {
                toast.error(`Couldn't approved ${name}`);
            })
    }

    const deny = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
        event.stopPropagation();
        denyCaddie(clubId, id)
            .then(async (r) => {
                refreshCaddies();
                toast.success(`${name} denied.`);
            })
            .catch(async (r) => {
                toast.error(`Couldn't deny ${name}`);
            })
    }

    return (
        <CaddieCard
            name={name}
            userId={userId}
            clubName={clubName}>
            <Card.Footer>
                <Button variant="primary" onClick={e => approve(e)} style={{paddingLeft: '2.5em', paddingRight: '2.5em', marginRight: '0.75em'}}>Approve</Button>
                <Button variant="outline-danger" onClick={e => deny(e)}>Deny</Button>
            </Card.Footer>
        </CaddieCard>
    )
}