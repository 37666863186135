import React from 'react';
import {Card, Col, Row} from "react-bootstrap";
import moment from "moment";
import Avatar from "../../Avatar";
import {UserAvailability} from "../../../services/ApiDomain";

type CaddieCardProps = {
    clubId: string
    date: string
    userAvailability: UserAvailability,
    onClick?: () => void,
    children?: React.ReactNode
};

const CaddieAvailabilityCard = ({clubId, date, userAvailability, onClick = () => {}, children}: CaddieCardProps) => {

    let userId = userAvailability.user.id
    let name = userAvailability.user.displayName
    let phoneNumber = userAvailability.user.phoneNumber

    let t = new Date(date)
    let window = ''
    let doubleBag = false
    let dateAvailabilities = userAvailability.availabilities.filter((a) => {
        // wow, lol! -> https://stackoverflow.com/questions/7556591/is-the-javascript-date-object-always-one-day-off
        let tStr = moment(date).format('YYYY-MM-DD')
        return a.date === moment(date).format('YYYY-MM-DD') && a.club?.id && a.club.id === clubId
    });

    if (dateAvailabilities.length === 1) {
        let availabilityResource = dateAvailabilities[0];
        doubleBag = !!availabilityResource.doubleBag

        if (availabilityResource.windows && availabilityResource.windows.length === 1) {
            let windowResource = availabilityResource.windows[0];
            let from = windowResource.from === '00:00' ? 'Start of Day' : windowResource.from
            let to = windowResource.to === '23:59' ? 'End of Day' : windowResource.to
            window = from + ' - ' + to
        }
    }

    return (
        <Card onClick={() => onClick()} className="caddieCard" style={{ cursor: 'default' }}>
            <Card.Body>
                <Row>
                    <Col xs={12} md={"auto"}>
                        <Avatar user={userId} size={'s'} />
                    </Col>
                    <Col>
                        <h4>{name}</h4>
                        {window && <p style={{ marginBottom: 0 }}><i>{window}</i></p>}
                        <p style={{ marginBottom: 0 }}>{doubleBag ? 'Will accept multiple bags' : 'Will only accept a single bag.'}</p>
                        {phoneNumber && <>
                            <div style={{ marginTop: '0.25em', marginBottom: '0.25em', borderTop: '1px solid #ccc' }}/>
                            <p style={{ marginBottom: 0 }}><i>{phoneNumber}</i></p>
                        </>}
                    </Col>
                </Row>
            </Card.Body>
            {children && children}
        </Card>
    )
}

export default CaddieAvailabilityCard;