import React, {useEffect, useState} from 'react';
import {Button, Col, FormControl, Row, Table} from "react-bootstrap";
import {UserCaddie} from "../../../../services/ApiDomain";
import Avatar from "../../../../components/Avatar";

import './CaddiesTable.scss';
import {CADDIE_PROFILE} from "../../../../services/PageUtils";
import {useNavigate} from "react-router-dom";
import {AddCircle, DeleteOutline} from "@material-ui/icons";
import AddNewCaddie from "../modals/AddNewCaddie";
import {darkerHandicaddieGreen} from "../../../../services/Utils";

interface Props {
    caddies: UserCaddie[]
    refreshCaddies: () => void
    filterFunc: (allCaddies: UserCaddie[], searchTerm: string) => UserCaddie[]
    showDeleteAlert: (id: string, name: string) => void
    loading?: boolean
}

const CaddiesTable = ({caddies, refreshCaddies, filterFunc, loading, showDeleteAlert}: Props) => {

    const navigate = useNavigate();

    const [showAddModal, setShowAddModal] = useState<boolean>(false)
    const [searchTerm, setSearchTerm] = useState<string>('')
    const [filteredCaddies, setFilteredCaddies] = useState<UserCaddie[]>([]);

    useEffect(() => {
        setFilteredCaddies(filterFunc(caddies, searchTerm.trim()))
    }, [caddies])

    useEffect(() => {
        setFilteredCaddies(filterFunc(caddies, searchTerm.trim()));
    }, [searchTerm])

    function navigateToCaddieProfile(caddie: UserCaddie) {
        navigate(CADDIE_PROFILE
            .replace(":clubId", caddie.clubId)
            .replace(":caddieId", caddie.caddieId));
    }

    return (
        <>
            <Row>
                <Col xs={12} md={6}>
                    <h3 className="pageSubHeading">Caddies</h3>
                </Col>
                <Col xs={12} md={6}>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Button
                            style={{ marginRight: '0.5em', backgroundColor: darkerHandicaddieGreen }}
                            onClick={() => setShowAddModal(true)}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <AddCircle style={{ marginRight: '0.5em' }}/> New
                            </div>
                        </Button>
                        <FormControl type="text" placeholder="Search"
                                     onChange={(e) => setSearchTerm(e.target.value)}/>
                    </div>
                </Col>
            </Row>

            <Row>
                <Table borderless>
                    <thead className={"caddiesTable_headingContainer"}>
                        <tr>
                            <td>Name</td>
                            <td>Club</td>
                            <td>Contact Number</td>
                            <td>Profile</td>
                        </tr>
                    </thead>
                    <tbody className={"caddiesTable_bodyContainer"}>
                        {!loading && filteredCaddies && filteredCaddies.map(caddie => {
                            return <tr key={caddie.caddieId}>
                                <td>
                                    <Avatar size={"xs"} user={caddie.userId}/>
                                    {caddie.displayName}
                                </td>
                                <td>{caddie.clubName}</td>
                                <td>{caddie.phoneNumber}</td>
                                <td>
                                    <span
                                        className={"caddiesTable_viewProfileBtn"}
                                        onClick={() => navigateToCaddieProfile(caddie)}>
                                        VIEW
                                    </span>

                                    <DeleteOutline
                                        className={"caddiesTable_deleteBtn"}
                                        onClick={() => showDeleteAlert(caddie.caddieId, caddie.displayName)} />
                                </td>
                            </tr>
                        })}
                        {!loading && filteredCaddies && filteredCaddies.length === 0 && <tr>
                            <td className={"caddiesTable_colOne"}>No caddies...</td>
                            <td/>
                            <td/>
                            <td/>
                        </tr>}
                        {loading && <tr>
                            <td className={"caddiesTable_colOne"}>Loading...</td>
                            <td/>
                            <td/>
                        </tr>}
                    </tbody>
                </Table>
            </Row>

            <AddNewCaddie
                show={showAddModal}
                close={() => setShowAddModal(false)}
                onSuccess={() => {
                    refreshCaddies()
                    setShowAddModal(false)
                }} />
        </>
    )
}

export default CaddiesTable;