import React, {useEffect, useState} from "react";
import {BookingsTable} from "../parts/caddie/bookings/table/BookingsTable";
import {useStore} from "../services/state/useStore";
import {logErrorFromApi} from "../services/Utils";
import {useApi} from "../services/useApi";
import {UserBooking} from "../services/ApiDomain";

interface Props {

}

const MyBookings = ({}: Props) => {

    const { currentUser } = useStore();
    const { getBookings } = useApi();

    const [bookings, setBookings] = useState<UserBooking[]>([]);
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        loadBookings()
            .catch(err => logErrorFromApi(err));
    }, [currentUser])

    const loadBookings = async () => {
        if (!currentUser) {
            return;
        }

        try {
            setLoading(true);
            setBookings(await getBookings(currentUser.id));
        } finally {
            setLoading(false);
        }
    }

    return (
        <>
            <h1 className={"pageHeading"}>Future Bookings</h1>
            <BookingsTable bookings={bookings} loading={loading} />
        </>
    )

}

export default MyBookings;