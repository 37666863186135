import React from "react";
import {Container} from "react-bootstrap";
import UserProfileHeader from "./header/UserProfileHeader";
import {ProfileContent} from "./content/ProfileContent";

interface Props {
    userId: string
    isCurrentUser: boolean
    displayName: string
    email?: string
    phone?: string
    bio?: string
    isCaddieMasterForUser: boolean
    caddieId?: string
    clubId?: string
    clubName?: string
}

export const Profile = ({
                            userId,
                            isCurrentUser,
                            displayName,
                            email,
                            phone,
                            bio,
                            isCaddieMasterForUser,
                            caddieId,
                            clubId,
                            clubName
                        }: Props) => {
    return <>
        <UserProfileHeader userId={userId} displayName={displayName} isCurrentUser={isCurrentUser} />
        <Container className={"contentContainer"} fluid="lg">
            <ProfileContent
                email={email}
                phoneNumber={phone}
                bio={bio}
                isCaddieMasterForUser={isCaddieMasterForUser}
                caddieId={caddieId}
                clubId={clubId}
                clubName={clubName}
            />
        </Container>
    </>

}