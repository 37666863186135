import {BookingSlot, ClubBookingSlot} from "../../../ApiDomain";

export enum Drawer {
    AVAILABLE_CADDIES,
    BOOKING,
    SETTINGS
}

export enum DrawerStatus {
    OPEN = 70,
    CLOSED = 95
}

export interface CreateBookingForm {
    teeTime: string
    bookingId?: string
    title?: string
    notes?: string
    shareNote?: boolean
    courseId?: string
    endTeeTime?: string
    // updatedNoTeeTime?: string
    bookingSlots?: BookingSlot[]
    // isFromTimeSheet?: boolean
}

export interface SlotCalculationErrorResponse {
    slots: ClubBookingSlot[]
    error?: "TOO_MANY_NON_EMPTY_SLOTS"
}

export interface DeleteModalAction {
    show: boolean
    bookingId?: string
}
